import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <React.Fragment>
      <header className="bg-image">
        <div className="bg-container">
          <h1>EFCS</h1>
          <h2>Executive Financial Consulting Services</h2>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
