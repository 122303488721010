import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Layout from './layout/Layout';
import Home from './components/pages/Home';
import AboutUs from './components/pages/AboutUs';
import ContactUs from './components/pages/ContactUs';
import Resources from './components/pages/Resources';
import Videos from './components/pages/Videos';
import Planning from './components/pages/Planning';
import Reviews from './components/pages/Reviews';
import Services from './components/pages/Services';
import ScrollToTop from './js/ScrollToTop';

import './App.css';

function App() {
  return (
    <div className="body-wrap">
      <Router>
        <Layout>
          <ScrollToTop />
          <Switch>
            <Route path={'/AboutUs'} component={AboutUs} />
            <Route path={'/ContactUs'} component={ContactUs} />
            <Route path={'/Resources'} component={Resources} />
            <Route path={`/Videos`} component={Videos} />
            <Route path={'/Planning'} component={Planning} />
            <Route path={'/Reviews'} component={Reviews} />
            <Route path={'/Services'} component={Services} />
            <Route path={'/'} component={Home} />
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
