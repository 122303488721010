import React from 'react';
import Line from '../misc/line/Line';
import Pgraph from '../misc/pgraph/Pgraph';
import VideoEmbed from '../misc/videoEmbed/VideoEmbed';

const Videos = () => {
  return (
    <React.Fragment>
      <section className="content-container">
        <div className="textArea">
          <h2>EFCS Videos</h2>
        </div>

        <Line />

        <div className="content">
          <div className="video-container">
            <h3 className="video-title">EFCS Link Training Chapter 1</h3>
            <Pgraph>
              Instructions for logging in to the Intuit LINK starting with the
              e-mail invitation through to the home page.
            </Pgraph>
            <VideoEmbed
              embedId="uIC48RWtqV8?list=PL6phO53N-zBavpkNIYCLnKQHw0hPqhw3R"
              title="EFCS Link Training Chapter 1"
              width={853}
              height={480}
            />
          </div>
          <Line />
          <div className="video-container">
            <h3 className="video-title">EFCS Link Training Chapter 2</h3>
            <Pgraph>
              Instructions for navigating the Intuit LINK focusing on the To Do
              page to collect and organize the tax data.
            </Pgraph>
            <VideoEmbed
              embedId="t653jIuvohM?list=PL6phO53N-zBavpkNIYCLnKQHw0hPqhw3R"
              title="EFCS Link Training Chapter 2"
              width={853}
              height={480}
            />
          </div>
          <Line />
          <div className="video-container">
            <h3 className="video-title">EFCS Link Training Chapter 3</h3>
            <Pgraph>
              Instructions on Document management in the Intuit LINK including
              how to upload and download your documents.
            </Pgraph>
            <VideoEmbed
              embedId="U6mjbG4EWsc?list=PL6phO53N-zBavpkNIYCLnKQHw0hPqhw3R"
              title="EFCS Link Training Chapter 3"
              width={853}
              height={480}
            />
          </div>
          <Line />
          <div className="video-container">
            <h3 className="video-title">EFCS Link Training Chapter 4</h3>
            <Pgraph>
              Instructions on using the Intuit LINK to complete the tax
              preparation process and retrieving your returns.
            </Pgraph>
            <VideoEmbed
              embedId="xEh9RUhwM7g?list=PL6phO53N-zBavpkNIYCLnKQHw0hPqhw3R"
              title="EFCS Link Training Chapter 4"
              width={853}
              height={480}
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Videos;
