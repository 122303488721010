import React from 'react';
import Line from '../misc/line/Line';
import Pgraph from '../misc/pgraph/Pgraph';

const AboutUs = () => {
  return (
    <React.Fragment>
      <section className="content-container" style={{ lineHeight: '1.25' }}>
        <div className="textArea">
          <h2>About EFCS</h2>
        </div>

        <Line />

        <div className="content">
          <Pgraph>
            After graduating from the University of Cincinnati College of Law in
            1982, I became a member of the Ohio Bar in 1983. After a few years
            as a Financial Planner at a large insurance company, I started my
            own company, Executive Financial Consulting Services, which
            ultimately focused on tax planning and preparation. Having worked
            since I was a freshman to pay for my high school tuition, I had the
            opportunity to develop a strong work ethic. My Dad always told me,
          </Pgraph>
          <Pgraph>
            "<em>A job worth doing is worth doing right!"</em>
          </Pgraph>
          <Pgraph>
            {' '}
            Scouting also taught me to "Do your best": no matter the size of the
            task or the type of job, the client will get my best.
          </Pgraph>
          <Pgraph>
            Having been self-sufficient from an early age, my diverse work
            experience enables me to relate to more clients for a stronger
            working relationship. Also, as a performing musician for over almost
            50 years and a sound engineer for almost the same length of time, I
            am very familiar with the music industry with a significant portion
            of my clientele in that industry. As a business owner for more than
            35 years, my personal experience gives me an advantage when working
            with professionals and other small business owners.
          </Pgraph>
          <Pgraph>
            Our family have resided in Montgomery since 1985. I have involved
            with the community including almost 25 years as a leader with the
            Boy Scouts, and 5 years as treasurer of the Cincinnati Contemporary
            Jazz Orchestra, a local non-profit organization.
          </Pgraph>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
