import React, { useState } from 'react';
import { send } from 'emailjs-com';
import Line from '../misc/line/Line';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Default state to reset to when submit succeeds
const initialState = {
  username: '',
  email: '',
  subject: '',
  phone: '',
  message: '',
};

// State controller
const ContactUs = () => {
  const [toSend, setToSend] = useState({
    name: '',
    subject: '',
    email: '',
    phone: '',
    message: '',
  });
  // Reset form and State
  const clearState = () => {
    setToSend({ ...initialState });
  };

  // Submit form
  const onSubmit = (e) => {
    e.preventDefault();
    // emailJS send() call
    send(
      'service_snw08ba',
      'template_7dbrk0f',
      toSend,
      'user_Opy19OYGBGrnTROakSVaa'
    )
      // Sucess call
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        toast.success('Your inquiry has been submitted!', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        clearState();
      })
      // Error Call
      .catch((err) => {
        console.log('FAILED...', err);
        toast.error(
          'Your inquiry submission has failed. Please try again later.',
          {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };

  // Form Input change
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <React.Fragment>
      <section className="content-container">
        <div className="textArea">
          <h2>Contact Us</h2>
          <p>
            We would love to hear from you!
            <br />
            For any inquiries, please send us an email or give us a call.
          </p>
        </div>

        <Line />

        <div className="block">
          <div className="row">
            <div className="col-left">
              <form id="contact" action="" onSubmit={onSubmit}>
                <h4>Ask us a question or leave us a message</h4>
                <fieldset>
                  {/* 
                    name: used to key the handleChange() function
                    onChange: calls the handleChange() function
                    value: value of the input itself (gets updated through state)
                  */}
                  <input
                    placeholder="Your Name"
                    name="name"
                    type="text"
                    tabIndex="1"
                    onChange={handleChange}
                    value={toSend?.name || ''}
                    required
                    autoFocus
                  />
                </fieldset>
                <fieldset>
                  <input
                    placeholder="Subject"
                    name="subject"
                    type="text"
                    tabIndex="2"
                    required
                    autoFocus
                    onChange={handleChange}
                    value={toSend?.subject || ''}
                  />
                </fieldset>
                <fieldset>
                  <input
                    placeholder="Your Email Address"
                    name="email"
                    type="email"
                    tabIndex="3"
                    required
                    onChange={handleChange}
                    value={toSend?.email || ''}
                  />
                </fieldset>
                <fieldset>
                  <input
                    placeholder="Your Phone Number"
                    name="phone"
                    type="tel"
                    tabIndex="4"
                    required
                    onChange={handleChange}
                    value={toSend?.phone || ''}
                  />
                </fieldset>
                <fieldset>
                  <textarea
                    placeholder="Type your Message Here...."
                    name="message"
                    tabIndex="5"
                    required
                    onChange={handleChange}
                    value={toSend?.message || ''}
                  ></textarea>
                </fieldset>
                <fieldset>
                  <button
                    name="submit"
                    type="submit"
                    id="contact-submit"
                    data-submit="...Sending"
                  >
                    Submit
                  </button>
                </fieldset>
              </form>
            </div>
            <div className="col-right">
              <div id="details">
                <h4>Executive Financial Consulting Services</h4>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <i className="fa fa-map-marker fa- "></i>
                      </td>
                      <td>
                        Montgomery, Ohio
                        <br />
                        45242
                        <br />
                        <p></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fa fa-envelope fa- "></i>
                      </td>
                      <td>
                        Email:{' '}
                        <a href="mailto:james.kastrup@efcstax.com">
                          james.kastrup@efcstax.com
                        </a>
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td>
                        <i className="fa fa-phone fa- "></i>
                      </td>
                      <td>
                        Phone: <a href="tel:(513) 891-8828">(513) 891-8828</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fa fa-clock-o fa- "></i>
                      </td>
                      <td>
                        Office Hours:
                        <br />
                        9.00 am – 9.00 pm (Mon – Thur) <br />
                        9.00 am - 7.00 pm (Fri) <br />
                        10.00 am - 5.00 pm (Sat) <br />
                        12.00 pm - 5.00 pm (Sun) <br />
                      </td>
                    </tr>
                    <tr>
                      <td> </td>
                    </tr>
                    <tr>
                      <td> </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <span style={{ fontStyle: 'italic' }}>
                          Saturday and Sunday hours during tax season only.
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>
                        <span style={{ fontStyle: 'italic' }}>
                          Holidays may affect hours.
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactUs;
