import React from 'react';
import Line from '../misc/line/Line';
import Pgraph from '../misc/pgraph/Pgraph';

const ResourceLink = ({ title, link }) => {
  return (
    <div className="resource-link-container" style={{ marginBottom: '25px' }}>
      <p className="resource-link-title" style={{ marginBottom: '.25rem' }}>
        {title}
      </p>
      <p className="resource-link-anchor" style={{ marginTop: '.25rem' }}>
        <a href={link}>{link}</a>
      </p>
    </div>
  );
};

const Resources = () => {
  return (
    <React.Fragment>
      <section className="content-container">
        <div className="textArea">
          <h2>Client Resources</h2>
        </div>

        <Line />

        <div className="block">
          <h2>Resource Tips</h2>
          <h3 className="video-title">e-Sign Option</h3>
          <ResourceLink
            title="If you do not have a PDF editor, this program offers a free e-sign
            option with their free trial."
            link="https://www.irs.gov/newsroom/whats-hot"
          />

          <h3 className="video-title">Tax Scanning Tip</h3>
          <ResourceLink
            title="Don’t have a scanner or one with a sheet feeder?  Take your documents to the public library where they will scan them to a file for free.  Bring along a USB flash drive so they can download the scanned files to your drive."
            link="https://www.irs.gov/newsroom/whats-hot"
          />
        </div>

        <Line />

        <h2>Resource Links</h2>

        <div className="content">
          <ResourceLink
            title="IRS Breaking News"
            link="https://www.irs.gov/newsroom/whats-hot"
          />
          <ResourceLink
            title="IRS Refund Status"
            link="https://sa.www4.irs.gov/irfof/lang/en/irfofgetstatus.jsp"
          />
          <ResourceLink
            title="Kentucky - Where's my refund?"
            link="https://refund.ky.gov/"
          />
          <ResourceLink
            title="Ohio - Where's my refund?"
            link="https://tax.ohio.gov/wps/portal/gov/tax/individual/refund-status/check-my-refund-status"
          />
          <ResourceLink
            title="Massachusetts - Where's my refund?"
            link="https://mtc.dor.state.ma.us/mtc/?Link=REFUND"
          />
          <ResourceLink
            title="Maryland - Where's my refund?"
            link="https://interactive.marylandtaxes.gov/INDIV/refundstatus/home.aspx"
          />
          <ResourceLink
            title="Louisiana - Where's my refund?"
            link="https://latap.revenue.louisiana.gov/?Link=WMR"
          />
          <ResourceLink
            title="South Carolina - Where's my refund?"
            link="https://mydorway.dor.sc.gov/?link=refund"
          />
          <ResourceLink
            title="Virginia - Where's my refund?"
            link="https://www.tax.virginia.gov/wheres-my-refund"
          />
        </div>
      </section>
    </React.Fragment>
  );
};

export default Resources;
