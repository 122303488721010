import React from 'react';

const Line = () => {
  return (
    <div
      style={{
        border: '1px solid rgba(0,0,0,.25)',
        width: '100%',
        visibility: 'visible',
        margin: '2rem 0',
      }}
    />
  );
};

export default Line;
