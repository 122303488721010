import React from 'react';
import PropTypes from 'prop-types';
import './VideoEmbed.css';

const VideoEmbed = ({ embedId, title, width, height }) => (
  <div className="video-responsive">
    <iframe
      width={`${width}`}
      height={`${height}`}
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title}
    />
  </div>
);

VideoEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default VideoEmbed;
