import React from 'react';
import Line from '../misc/line/Line';
import Pgraph from '../misc/pgraph/Pgraph';

const Planning = () => {
  return (
    <React.Fragment>
      <section className="content-container">
        <div className="textArea">
          <h2>Tax Planning and Preparation</h2>
          <Pgraph>EFCS is here to make filing easy</Pgraph>
        </div>
        <Line />
        <div className="content">
          <Pgraph>
            If you are tired of being surprised or frustrated with the results
            of your tax return, a new approach might be in order: Tax Planning.
            Whether looking for simple projections for next year’s income and
            tax liability or assistance with a complete financial plan, EFCS
            offers scalable designs and scope. Knowing what to expect with some
            degree of certainty reduces stress. For many small businesses,
            income may fluctuate significantly year to year. Having a plan and
            understanding how the pieces interact will help determine the range
            of your tax liability. EFCS encourages clients to contact us at the
            beginning of the third quarter to review year to date data and help
            refine earlier projections. If adjustments are necessary, there is
            still time to act.
          </Pgraph>
          <Pgraph>
            At EFCS we try to make you feel at ease with our tax preparation
            process by getting to know your particular situation and keeping you
            informed of our progress. Before the pandemic, we could meet in
            person to answer your questions, address your concerns and discuss
            the necessary information needed to complete your returns. Meetings
            are now conducted digitally via videoconference where possible or
            over the phone. New clients will be asked to provide copies of tax
            returns for the prior two years, giving us a brief historical
            perspective. After the initial interview, an engagement letter is
            sent to the client, which outline the duties of the client, the
            responsibilities of EFCS as well as our fees. For existing clients,
            a checklist of data and documents required is sent which is
            generated from the prior year’s return. EFCS also sends
            questionnaires tailored to the clients’ needs including expense
            templates. The collection of data is done through a 256-bit
            encrypted portal which protects and secures the client’s data as
            well as helps the client keep track of their data collection
            progress. Additional information may be requested as the return is
            prepared and will be communicated through the portal as well.
          </Pgraph>
          <Pgraph>
            Once the return is prepared, a draft is forwarded to the client for
            preview. A second videoconference is scheduled where the preparer
            walks through each page of the final returns and answers any
            questions. After the review, any necessary changes are made and the
            returns along with the e-file authorization forms, are signed and
            dated. Federal and state returns are e-filed once signed forms are
            returned to EFCS. For more information, please contact us at{' '}
            <a href="tel:(513) 891-8828"> (513) 891-8828</a>.
          </Pgraph>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Planning;
