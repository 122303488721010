import './MenuBar.css';
import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuBar = () => {
  return (
    <nav className="header">
      <div className="nav-wrapper">
        <a className="logo" href="/">
          EFCS
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>

        <ul className="menu">
          <li>
            <NavLink to="/" exact={true} activeClassName="nav-active">
              <span>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/Planning" activeClassName="nav-active">
              <span>Planning</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/Services" activeClassName="nav-active">
              <span>Services</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/Reviews" activeClassName="nav-active">
              <span>Reviews</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/Resources" activeClassName="nav-active">
              <span>Resources</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/Videos" activeClassName="nav-active">
              <span>Videos</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/AboutUs" activeClassName="nav-active">
              <span>About Us</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/ContactUs" activeClassName="nav-active">
              <span>Contact Us</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default MenuBar;
