import React from 'react';
import user_m from '../../../images/user_m_2.png';
import user_f from '../../../images/user_f.png';
import Line from '../line/Line';

import './Testimonials.css';
import Pgraph from '../pgraph/Pgraph';

const Home = () => {
  return (
    <React.Fragment>
      <section className="content-container">
        <div className="textArea">
          <h2>Referrals</h2>
          <Pgraph>
            Word of mouth advertising continues to be the largest source of new
            clients, and we want to continue to encourage that by offering a
            referral discount program. Any existing client who refers someone
            who signs an engagement letter and uses our service will receive a
            $100 credit on their next invoice.
          </Pgraph>
          <Pgraph>
            There are no limitations as to the number of referrals that can be
            made and credits in excess of the current year's fees will be
            carried forward.
          </Pgraph>
          <Pgraph>
            <em>
              Referral Discounts not available in conjunction with any other
              discounts or offers
            </em>
          </Pgraph>
        </div>
        <Line />
        <div className="textArea">
          <h2>Reviews</h2>
          <Pgraph>
            We love to hear feedback from our clients and would appreciate
            hearing from you.
          </Pgraph>
          <Pgraph className="review-statement">
            If you are an EFCS client and would like to send us a review, please
            do so by emailing{' '}
            <a href="mailto:james.kastrup@efcstax.com?subject=EFCS%20Client%20Review">
              james.kastrup@efcstax.com
            </a>
          </Pgraph>
        </div>

        <Line />

        <div className="cust-container">
          <img src={user_f} alt="Avatar" style={{ width: '90px' }} />
          <p>
            <span>Hannah L.</span> Client since 2023
          </p>
          <p>
            This was the least stressful tax season I've ever had! Using EFCS
            was so easy. Jim was easy to work with and very knowledgeable.
            Everything is online now which means he could complete everything in
            Cincinnati while I live in Columbus. EFCS's rates are very
            reasonable and we are already taking advantage of the referral
            discount! I can't say enough good things about our experience with
            EFCS. We are definitely going to be repeat customers and keep
            referring him to all of our family and friends.
          </p>
        </div>
        <div className="cust-container">
          <img src={user_f} alt="Avatar" style={{ width: '90px' }} />
          <p>
            <span>LeAnne A.</span> Client since 2011
          </p>
          <p>
            If you are looking for a tax professional that goes above and beyond
            your W-2s, that is EFCS. They are courteous, inquisitive,
            professional and most important, knowledgeable about the latest tax
            laws and regulations. We have recommended EFCS to all of our friends
            and colleagues.
          </p>
        </div>
        <div className="cust-container">
          <img src={user_m} alt="Avatar" style={{ width: '90px' }} />
          <p>
            <span>Scott B.</span> Client since 2017
          </p>
          <p>
            My wife and I have been working with Jim since 2016 and we couldn't
            be happier! Jim is extremely knowledgeable and excels at the
            challenges of tax preparation. He is very patient with our questions
            and is always willing to explain his process and what we can be
            doing to prepare for future tax seasons given any professional or
            personal changes that may occur. Finally, he is genuinely excited
            about solving every individual's tax challenge, which is exactly
            what you want in a tax professional. I highly recommend his
            services!
          </p>
        </div>
        <div className="cust-container">
          <img src={user_f} alt="Avatar" style={{ width: '90px' }} />
          <p>
            <span>Emily S.</span> Client since 2021
          </p>
          <p>
            This was the smoothest tax preparation ever! Because of the
            pandemic, Jim arranged a video chat so he could show me my return.
            He explained everything in terms even I could understand. I’m
            extremely happy with Jim’s work on my behalf.
          </p>
        </div>
        <div className="cust-container">
          <img src={user_m} alt="Avatar" style={{ width: '90px' }} />
          <p>
            <span>Nick P.</span> Client since 2000
          </p>
          <p>
            Being a client of EFCS services over the past 22 years has helped my
            business maintain a solid accounting foundation. EFCS has made it
            easy for me to maintain and comply with continuous tax code changes.
            I would recommend EFCS to anyone for their ultimate tax preparation
            and accounting services.
          </p>
        </div>
        <div className="cust-container">
          <img src={user_f} alt="Avatar" style={{ width: '90px' }} />
          <p>
            <span>Lauren G.</span> Client since 2016
          </p>
          <p>
            Jim is phenomenal to work with! He takes care of tracking everything
            required to file with great online tools. It makes it so easy to
            send and receive all of my tax documents, making filing an absolute
            breeze.
          </p>
        </div>
        <div className="cust-container">
          <img src={user_m} alt="Avatar" style={{ width: '90px' }} />
          <p>
            <span>David H.</span> Client since 2014
          </p>
          <p>
            I've had a great experience working with EFCS. The taxes have been
            done properly and on time every year, and the rates are very
            resonable. I would recommend EFCS to individuals or small businesses
            looking for tax preparation services!
          </p>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
